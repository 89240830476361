import { LinkPlatform } from "../Services/Connections/Models/Connection"

import facebook from '../Resources/Icons/SocialAccounts/facebook.png'
import instagram from '../Resources/Icons/SocialAccounts/instagram.png'
import linkedin from '../Resources/Icons/SocialAccounts/linkedin.png'

class SocialAccountAssetProvider {

  private static _instance: SocialAccountAssetProvider

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  public iconForPlatform(platform: LinkPlatform) {
    switch (platform) {
      case LinkPlatform.facebook:
        return facebook
      case LinkPlatform.instagram:
        return instagram
      case LinkPlatform.linkedin:
        return linkedin
      default:
        return null
    }
  }
}

const socialAccountAssetProvider = SocialAccountAssetProvider.Instance
export default socialAccountAssetProvider