import { Connection } from "./Models/Connection"
import axios from 'axios';
import { Errors } from "../Errors"
import apiNetworkingClient from "../Networking/APINetworkClient";

class ConnectionService {

    public async scanConnectionWith(id: String) {

        let params = {
            "connection-id": id,
        };

        var pathTemplate = '/connections/{connection-id}/scan';
        var method = 'POST';

        try {
            let result = await this.makeRequest(method, pathTemplate, params)
            return result
        } catch (error) {
            this.handle(error);
        }
    }

    public async getMyConnections(): Promise<[Connection] | null> {

        var pathTemplate = '/users/self/connections';
        var method = 'GET';

        try {
            let result = await this.makeRequest<[Connection]>(method, pathTemplate, null)
            return result
        } catch (error) {
            this.handle(error);
            return null
        }
    }

    public async getConnectionWith(id: string): Promise<Connection | null> {

        var pathTemplate = '/connections/' + id;
        var method = 'GET';

        try {
            let result = await this.makeRequest<Connection>(method, pathTemplate, null)
            return result
        } catch (error) {
            this.handle(error);
            return null
        }
    }

    // PRIVATE

    private handle(error: unknown): Errors {
        console.log(error);
        if(axios.isAxiosError(error)) {
            const errorCode = error.response?.data.error;
            throw this.handleError(errorCode);
        }
        throw Errors.Unknown;
    }
    
    private handleError(code: string): Errors {
        switch (code) {
            case "__ConnectionServiceError.connectionAlreadyScanned": return Errors.ConnectionAlreadyScanned;
            case "__ConnectionServiceError.usersAlreadyConnected": return Errors.UsersAlreadyConnected;
            case "__ConnectionServiceError.noDataReturned": return Errors.ConnectionNotFound;
        }
        return Errors.Unknown;
    }

    private async makeRequest<R>(method: string, pathTemplate: string, params: any | undefined): Promise<R> {
        return new Promise(function(resolve, reject) {
            apiNetworkingClient.request(method, pathTemplate, null, params, (result) => { 
                if (result instanceof Error) {
                    console.log("API Error: " + result)
                    reject(result)
                } else {
                    resolve(result)
                }
            })
        });
    }
}

export default ConnectionService;