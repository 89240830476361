import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { User } from "../../../Services/User/Models/User";
import AccountOptions from "../../Components/AccountOptions/AccountOptions";
import UserAvatar from "../../Components/Avatar/Avatar";

const Account: React.FC<{user: User, closePopup: () => void}> = ({user, closePopup}) => {

  return (
    <Stack 
      direction="column" 
      alignItems="center" 
      spacing="8pt" 
      sx={{
        minWidth: "200pt",
        padding: "8pt",
        paddingTop: "16pt",
      }}>
      <UserAvatar user={user} size={60} />
      <Typography variant="h2" sx={{ color: (theme) => theme.palette.navbarText.main}} gutterBottom>{user?.name}</Typography>
      <AccountOptions user={user} closePopup={closePopup} />
    </Stack>
  )
}

export default Account;
