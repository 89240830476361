
import Cookies from "js-cookie"
import { Amplify, Auth } from 'aws-amplify'

import { CredentialsLogin, GetCredentialsRequest } from "./Models/GetCredentialsRequest"
import { Config } from "../../Utilities/Config"

class AuthenticationService {

  private static _instance: AuthenticationService
  private isFetchingCredentials: boolean

  public updateSignInState?: (signedIn: boolean) => void

  private constructor() {

    this.isFetchingCredentials = false

    Amplify.configure({
      Auth: {
        userPoolId: Config.userPoolId(),
        region: 'eu-west-2',
        userPoolWebClientId: Config.clientId(),
        cookieStorage: { 
          domain: window.location.hostname, 
          path: "/", 
          expires: 5, 
          secure: false,
        }
      }
    })
  }

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  public async isAuthenticated() {
    try {
      await Auth.currentAuthenticatedUser()
      return true
    } catch {
      return false
    }
  }

  public hasCredentials() {
    const ak = Cookies.get('ak')
    const sk = Cookies.get('sk')
    const st = Cookies.get('st')
    const hasCreds = ak != null && sk != null && st != null
    return hasCreds
  }

  private identity(): string | undefined {
    let identity = Cookies.get('iid')
    return identity
  }

  private async getLoginToken(): Promise<CredentialsLogin | undefined> {
    try {
      let session = await Auth.currentSession()
      let token = session.getIdToken().getJwtToken()

      return {
        type: "Cognito",
        token: token
      }
    } catch (error) {
      console.log("User Session Error: " + error)
    }
  }

  public async fetchCredentials() {

    if (this.isFetchingCredentials === false) {
      this.isFetchingCredentials = true

      console.log("Fetching Creds")

      const identity: string | undefined = this.identity()
      const login: CredentialsLogin | undefined = await this.getLoginToken()

      const body: GetCredentialsRequest = {
        identityId: identity,
        login: login
      }

      console.log(body.toString())

      const endpoint = Config.credentialsEndpoint()
      let response = await fetch(endpoint + "/tokens", {
        method: "POST",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        cache: "no-store"
      })
      let result = await response.json()

      console.log("Got Creds")

      const expiry = new Date(result.credentials.Credentials.Expiration)
      Cookies.set('iid', result.credentials.IdentityId)
      Cookies.set('ak', result.credentials.Credentials.AccessKeyId, { expires: expiry, })
      Cookies.set('sk', result.credentials.Credentials.SecretKey, { expires: expiry, })
      Cookies.set('st', result.credentials.Credentials.SessionToken, { expires: expiry, })

      this.isFetchingCredentials = false
    } else {
      console.log("wait for creds")
      await this.waitForCredentials()
    }
  }

  private async waitForCredentials() {
    return new Promise<void>((resolve) => {
      const wait = () => {
        setTimeout(() => { 
          if (this.isFetchingCredentials === false) {
            resolve()
          } else {
            wait()
          }
         }, 300)
      }
      wait()
    })
  }

  public async login(email: string, password: string) {
    try {
      const user = await Auth.signIn(email, password)
      await this.fetchCredentials()
      if (this.updateSignInState != null) {
        this.updateSignInState(true)
      }
      return user
    } catch (error) {
      throw error
    }
  }

  public async signUp(email: string, password: string) {

    const signUpRequest = {
      username: email,
      password: password
    }
    try {
      await Auth.signUp(signUpRequest)
    } catch (error) {
      throw error
    }
  }

  public async verifyEmail(username: string, code: string, password: string) {
    try {
      await Auth.confirmSignUp(username, code)
      return await this.login(username, password)
    } catch (error) {
      throw error
    }
  }

  public async resendVerificationEmail(username: string) {
    try {
      await Auth.resendSignUp(username)
    } catch (error) {
      throw error
    }
  }

  public async signout() {
    await Auth.signOut()
    this.clearCookies()
    await this.fetchCredentials()
    if (this.updateSignInState != null) {
      this.updateSignInState(false)
    }
  }

  private clearCookies() {
    Cookies.remove('iid')
    Cookies.remove('ak')
    Cookies.remove('sk')
    Cookies.remove('st')
  }
}

const authService = AuthenticationService.Instance
export default authService