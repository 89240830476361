import { User } from "../../User/Models/User"

export interface Connection {
    id: string
    dateConnected: string
    sharedIdCard: {
        id: string
        owningUser: string
        data: {
            emailAddress: string
            phoneNumber: {
                countryCode: number
                number: string
            }
            links: Link[]
        }
    }
    sharedUser: User
}

export interface Link {
    id: string,
    link: string,
    platform: LinkPlatform,
    name: string
}

export enum LinkPlatform {
    facebook = "facebook",
    linkedin = "linkedin",
    instagram = "instagram",
}