import axios from 'axios';
import { Errors } from "../Errors"
import apiNetworkingClient from "../Networking/APINetworkClient";
import { User } from './Models/User';

class UserService {

    public async getSelf(): Promise<User | undefined> {

        var pathTemplate = '/users/self';
        var method = 'GET';

        try {
            let result = await this.makeRequest<User>(method, pathTemplate, null)
            return result
        } catch (error) {
            this.handle(error);
        }
    }

    public async setName(name: string): Promise<User | undefined> {

        var pathTemplate = '/users/self';
        var method = 'PATCH';

        const body = {
            name: name
        }

        try {
            let result = await this.makeRequest<User>(method, pathTemplate, null, body)
            return result
        } catch (error) {
            this.handle(error);
        }
    }

    // PRIVATE

    private handle(error: unknown): Errors {
        if(axios.isAxiosError(error)) {
            const errorCode = error.response?.data.error;
            throw this.handleError(errorCode);
        }
        throw Errors.Unknown;
    }
    
    private handleError(code: string): Errors {
        switch (code) {
        }
        return Errors.Unknown;
    }

    private async makeRequest<R>(method: string, pathTemplate: string, params?: any | undefined, body?: any | undefined): Promise<R> {
        console.log(body)
        return new Promise(function(resolve, reject) {
            apiNetworkingClient.request(method, pathTemplate, body, params, (result) => { 
                if (result instanceof Error) {
                    reject(result)
                } else {
                    resolve(result)
                }
            })
        });
    }
}

export default UserService;