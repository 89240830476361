import { Stack } from "@mui/system";
import React from "react";
import { User } from "../../../../Services/User/Models/User";
import UserAvatar from "../../Avatar/Avatar";

import { Typography } from "@mui/material";

const IdentityCardHeader: React.FC<{user: User}> = ({user}) => {

    return (
        <Stack 
          direction="column"
          alignItems="center"
          spacing="14pt"
          sx={{ 
            padding: "20pt",
          }}
          >
            <UserAvatar user={user} size={50}/>
            <Typography variant="h4" sx={{ textShadow: "0pt 0pt 20pt #000, 0pt 0pt 2pt #0006", textAlign: "center" }} >{user.name}</Typography>
        </Stack>
    );
};

export default IdentityCardHeader;
