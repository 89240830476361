import { AppBar, Backdrop, Button, IconButton, Popover, Toolbar, Box } from "@mui/material"
import React, { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Context } from "../../../State/ContextProvider"
import Account from "../../Pages/Account/Account"

import MenuIcon from '@mui/icons-material/Menu'
import LoginIcon from '@mui/icons-material/Login'
import { useTheme } from "@emotion/react"
import UserAvatar from "../Avatar/Avatar"

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    navbar: true
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    navbarText: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    navbarText: true
  }
}

const NavBar: React.FC = () => {

  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [loginButtonTarget, setLoginButtonTarget] = useState<HTMLButtonElement | HTMLDivElement | null>(null)
  const { state } = useContext(Context)

  const theme: any = useTheme();

  const loginButtonRef = useRef<HTMLDivElement>(null)

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  }

  const openAccount = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setLoginButtonTarget(event.currentTarget);
  }

  const closeAccountPopover = () => {
    setLoginButtonTarget(null)
  }

  const loginOpen = loginButtonTarget !== null
  const loginId = loginButtonTarget ? 'login-popover' : undefined
  const loginButtonWidth = loginButtonRef.current?.offsetWidth ?? 0
  const popupVerticalOffset = () => 36
  const showAccountButton = () => state.isSignedIn

  const buttonStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    '&:hover': {
      background: "palette.secondary",
    }
  };

  const toolbarStyle = {
    zIndex: theme.zIndex.drawer + 2,
    borderBottom: process.env.REACT_APP_STAGE === "production" ? "unset" : "1pt solid #ff0000"
  };

  const popover: React.FC = () => {
    return (
      <Popover
        id={loginId}
        open={loginOpen}
        anchorEl={loginButtonTarget}
        onClose={closeAccountPopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            borderRadius: "unset",
            backgroundColor: "transparent",
            boxShadow: "unset",
            backgroundImage: "unset"
          }
        }}>
        <Box
          sx={{
            position: "relative",
            mt: `${popupVerticalOffset()}pt`,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              width: 18,
              height: 18,
              top: -8,
              transform: "rotate(45deg)",
              right: (loginButtonWidth / 2) - 9,
              backgroundColor: theme.palette.navbar.main,
              borderLeft: "1pt solid #fff2",
              borderTop: "1pt solid #fff2",
            }
          }}
        />
        <div style={{ backgroundColor: theme.palette.navbar.main, borderRadius: "10pt", border: "1pt solid #fff2" }}>
          <Account user={state.currentUser!} closePopup={closeAccountPopover} />
        </div>
      </Popover>
    )
  }

  return (
    <div>
      <AppBar
        color="navbar"
        enableColorOnDark
        position="fixed"
        sx={{
          borderBottom: "1pt solid #fff2",
          boxShadow: (theme) => theme.palette.mode == "dark" ? "unset" : theme.shadows[3],
          backgroundImage: "unset",
          zIndex: (theme) => theme.zIndex.drawer + 100
        }}>
        <Toolbar sx={toolbarStyle}>
          <Box sx={{ flexGrow: 1, display: { mobile: 'none', tablet: 'none' } }}>
            // This is disabled until we add more nav options
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              color="navbarText"
              onClick={toggleSidebar}
              sx={buttonStyle}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { mobile: 'flex', tablet: 'flex' } }}>
            <Button
              disableRipple
              size="large"
              color="navbarText"
              component={Link}
              to="/"
              sx={buttonStyle}>
              LNKR
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {state.currentUser !== undefined 
            ?
              showAccountButton()
              ?
                <div ref={loginButtonRef}>
                  <IconButton
                    disableRipple
                    size="large"
                    aria-label="User account"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="navbarText"
                    onClick={openAccount}
                    sx={{ ...buttonStyle, zIndex: (theme) => theme.zIndex.drawer + 3, padding: "unset" }}>
                      <UserAvatar user={state.currentUser} bordered size={32} />
                  </IconButton>
                </div>
              :
                <div ref={loginButtonRef}>
                  <Button
                    disableRipple
                    size="large"
                    aria-label="Login to lnkr"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="navbarText"
                    sx={{ ...buttonStyle, zIndex: (theme) => theme.zIndex.drawer + 3 }}
                    endIcon={<LoginIcon />}
                    component={Link}
                    to="/login">
                    Login
                  </Button>
                </div>
            :
              null
            }
            {loginButtonTarget != null ? popover({}) : null}
          </Box>
        </Toolbar>
        {/* <Box sx={{ height: "15pt", backgroundColor:"#cc0000", zIndex: (theme) => theme.zIndex.drawer + 101, fontSize: "12pt", lineHeight: "12pt", textAlign: "center" }}>staging</Box> */}
      </AppBar>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#0001", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)" }}
        open={loginOpen}
        onClick={closeAccountPopover} />
    </div>
  );
};

export default NavBar;
