
export namespace Config {

  export const userPoolId = (): string => {
    if (process.env.REACT_APP_STAGE === "production") {
      return "eu-west-2_gU5BuRQkz"
    } else {
      return "eu-west-2_zQrWu8iUN"
    }
  }

  export const clientId = (): string => {
    if (process.env.REACT_APP_STAGE === "production") {
      return "21vjth3pkk3q9q4hq481s5ojj0"
    } else {
      return "7q7vpcj2ud1fo5r1129rdv49ar"
    }
  }

  export const credentialsEndpoint = (): string => {
    if (process.env.REACT_APP_STAGE === "production") {
      return "https://auth.lnkr-app.com"
    } else {
      return "https://auth.lnkr-test-app.com"
    }
  }

  export const apiEndpoint = (): string => {
    if (process.env.REACT_APP_STAGE === "production") {
      return "https://api.lnkr-app.com"
    } else {
      return "https://api.lnkr-test-app.com"
    }
  }
}
