
export enum Errors {
    ConnectionNotFound,
    ConnectionAlreadyScanned,
    UsersAlreadyConnected,
    Unknown
}

export interface ErrorModel {
    description: string
    canRetry: boolean
    retryBlock?: () => void
}

export function errorModelFor(error: Errors, retry?: () => void): ErrorModel {
    switch (error) {
        case Errors.ConnectionAlreadyScanned:
            return {
                description: "That connection has alredy been scanned, please request a new one",
                canRetry: false
            }
        case Errors.ConnectionNotFound:
            return {
                description: "Connection couldnot be found, please request a new one",
                canRetry: false
            }
        case Errors.UsersAlreadyConnected:
            return {
                description: "You are already connected to this user",
                canRetry: false
            }
    }
    return {
        description: "Unknown error",
        canRetry: false
    }
}