import React, { useCallback, useContext, useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { validate as uuidValidate } from 'uuid';
import { Context } from "../../../State/ContextProvider";
import { Connection } from "../../../Services/Connections/Models/Connection";
import ConnectionService from "../../../Services/Connections/ConnectionService";

import IdentityCardHeader from "../../Components/IdentityCard/IdentityCardHeader/IdentityCardHeader";
import IdentityCardContactDetails from "../../Components/IdentityCard/IdentityCardContactDetails/IdentityCardContactDetails";

const IdentityCard: React.FC = () => {

  const location = useLocation()

  const { state } = useContext(Context);

  const [fetchedConnection, setFetchedConnection] = useState<Connection | null>(null)

  const card_id = useCallback(() => {
    let last_element = location.pathname.split("/").pop()
    if (last_element !== null && uuidValidate(last_element!)) {
      return last_element
    }
  }, [location.pathname])

  const getCardIfNeeded = useCallback(() => {
    if (state.selectedConnection === null) { return }
    const service = new ConnectionService()
    const id = card_id()
    if (id !== undefined) {
      service.getConnectionWith(id).then(
        (message) => {
          setFetchedConnection(message)
        },
        (error) => {
          console.log(error)
        }
      )
    } else {
      // handle bad card ID
    }
  }, [card_id, state]);

  useEffect(() => {
    getCardIfNeeded()
  }, [getCardIfNeeded])

  const getConnection = () => {
    if (state.selectedConnection != null) {
      return state.selectedConnection
    } else if (fetchedConnection != null) {
      return fetchedConnection
    }
  }

  const connection = getConnection()

  return (
    <Box sx={{ minHeight: "300pt" }}>
        {connection !== undefined ?
          <Stack direction="column" alignItems="center" spacing="0pt" justifyContent="flex-start">
            <IdentityCardHeader user={connection.sharedUser} />
            <IdentityCardContactDetails connection={connection} />
          </Stack>
          :
          null
        }
    </Box>
  )
};

export default IdentityCard;