import Cookies from "js-cookie";

class EnvironmentHelper {

  private static _instance: EnvironmentHelper

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  public clearCookiesIfNeeded() {
    if (process.env.NODE_ENV !== 'production') {
      if (Cookies.get('env') !== process.env.REACT_APP_STAGE) {
        console.log("🤖 Change of env detected")
        this.deleteAllCookies()
        Cookies.set('env', process.env.REACT_APP_STAGE ?? "undefined")
      }
    }
  }

  private deleteAllCookies() {
    if (process.env.NODE_ENV !== 'production') {
      console.log("🚮 Deleting all Cookies")
      document.cookie.split(";").forEach(
        function(c) { 
          document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
        }
      )
    }
  }
}

const envHelper = EnvironmentHelper.Instance
export default envHelper