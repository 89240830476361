import { List, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import React from "react";
import { Connection, Link, LinkPlatform } from "../../../../Services/Connections/Models/Connection";

import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import socialAccountAssetProvider from "../../../../Utilities/SocialAccountAssetProvider";


const IdentityCardContactDetails: React.FC<{ connection: Connection }> = ({ connection }) => {

  return (
    <List sx={{ width: "90%" }}>
      { connection.sharedIdCard.data.phoneNumber && connection.sharedIdCard.data.phoneNumber.countryCode && connection.sharedIdCard.data.phoneNumber.number
        ?
          <IdentityCardContactRow key="phone number" icon={<PhoneIcon/>} body={`+${connection.sharedIdCard.data.phoneNumber.countryCode} ${connection.sharedIdCard.data.phoneNumber.number}`} link={`tel:+${connection.sharedIdCard.data.phoneNumber.countryCode} ${connection.sharedIdCard.data.phoneNumber.number}`} />
        :
          null
      }
      { connection.sharedIdCard.data.emailAddress
      ? 
        <IdentityCardContactRow key="email address" icon={<EmailIcon/>} body={connection.sharedIdCard.data.emailAddress} link={`mailto:${connection.sharedIdCard.data.emailAddress}`} />
      :
        null
      }
      { connection.sharedIdCard.data.links?.map((link) => (
        <IdentityCardLinkRow link={link} key={link.id} />
      ))}
    </List>
  );
};

const IdentityCardLinkRow: React.FC<{ link: Link }> = ({link}) => {

  const icon = () => {
    return socialAccountAssetProvider.iconForPlatform(link.platform)
  }

  return(
    <ListItemButton disableRipple sx={{ width: "100%", borderRadius: "10pt" }} href={link.link} target="_blank" key={link.id}>
      <ListItemIcon>
        <div style={{ height: "1.8em", width: "1.8em", WebkitMaskImage: `url(${icon()})`, WebkitMaskSize: "contain", WebkitMaskRepeat: "no-repeat", WebkitMaskPosition: "center", maskImage: `url(${icon()})`, maskSize: "contain", maskRepeat: "no-repeat", maskPosition: "center" }}>
          <div style={{ width: "100%", height: "100%", backgroundColor: "#fff" }} />
        </div>
      </ListItemIcon>
      <ListItemText primary={link.name} />
    </ListItemButton>
  )
}

const IdentityCardContactRow: React.FC<{ icon: React.ReactElement, body: string, link: string }> = ({icon, body, link}) => {

  return(
    <ListItemButton disableRipple sx={{ width: "100%", borderRadius: "10pt" }} href={link}>
      <ListItemIcon>
          {icon}
      </ListItemIcon>
      <ListItemText primary={body} />
    </ListItemButton>
  )
}

export default IdentityCardContactDetails;
