import { createContext, useReducer } from "react"
import authService from "../Services/Auth/AuthenticationService"
import { Connection } from "../Services/Connections/Models/Connection"
import { User } from "../Services/User/Models/User"
import { State } from "./State"

enum StateAction {
  setSignedIn,
  setUser,
  setSelectedConnection
}

type Action = {
  type: StateAction,
  value: any | undefined
}

const reducer = (state: State, action: Action) => {
  console.log("Running action: " + StateAction[action.type] + ", value: " + JSON.stringify(action.value))
  switch (action.type) {
    case StateAction.setSignedIn:
      return {
        ...state,
        isSignedIn: action.value
      }
    case StateAction.setUser:
      return {
        ...state,
        currentUser: action.value
      }
    case StateAction.setSelectedConnection:
      return {
        ...state,
        selectedConnection: action.value
      }
  }
}

const initialState: State = {
  isSignedIn: false,
  currentUser: undefined,
  selectedConnection: undefined
}

export const Context = createContext<{ state: State, updateSignInState?: (signedIn: boolean) => void, updateUser?: (user: User | undefined) => void, updateSelectedConnection?: (connection: Connection | undefined) => void }>({ state: initialState })
export const ContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {

  const [state, dispatch] = useReducer(reducer, initialState)

  const updateUser = (user: User | undefined) => {
    dispatch({ type: StateAction.setUser, value: user })
  }

  const updateSelectedConnection = (connection: Connection | undefined) => {
    dispatch({ type: StateAction.setSelectedConnection, value: connection })
  }

  const updateSignInState = (signedIn: boolean) => {
    const shouldClearUser = signedIn === false && state.isSignedIn === true
    dispatch({ type: StateAction.setSignedIn, value: signedIn })
    if (shouldClearUser) {
      dispatch({ type: StateAction.setUser, value: undefined })
    }
  }

  authService.updateSignInState = updateSignInState

  return (
    <Context.Provider value={{ state, updateSignInState, updateUser, updateSelectedConnection }}>
      {children}
    </Context.Provider>
  )
}