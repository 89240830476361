import React, { useContext, useState } from "react";
import authService from "../../../Services/Auth/AuthenticationService";
import { User } from "../../../Services/User/Models/User";

import LogoutIcon from '@mui/icons-material/Logout';
import { LoadingButton } from "@mui/lab";
import UserService from "../../../Services/User/UserService";
import { Context } from "../../../State/ContextProvider";

const AccountOptions: React.FC<{user: User | undefined, closePopup: () => void}> = ({user, closePopup}) => {

  const [loading, setLoading] = useState(false)
  const { updateUser } = useContext(Context)

  const signOut = () => {
    setLoading(true)
    authService.signout().then(
      () => {
        console.log("Signed out")
        setLoading(false)
        closePopup()
        const userService = new UserService()
        userService.getSelf().then(
          (user) => {
            updateUser!(user)
          }
        )
      },
      (error: any) => {
        console.log(error)
        setLoading(false)
      }
    )
  }

  return (
    <LoadingButton
      disableRipple
      variant="outlined"
      color="error"
      onClick={signOut}
      endIcon={<LogoutIcon />}
      loading={loading}
      disabled={loading}
    >
      Sign Out
    </LoadingButton>
  )
}

export default AccountOptions