import React from "react"
import { User } from "../../../Services/User/Models/User"
import { grey } from '@mui/material/colors';
import { Avatar } from "@mui/material"
import { Box, width } from "@mui/system";

const UserAvatar: React.FC<{ user: User | undefined, size?: number, bordered?: boolean }> = ({ user, size, bordered }) => {

  const viewSize = () => {
    return Math.round(size ?? 38)
  }

  const initials = () => {
    const name = user?.name
    if (name !== undefined) {
      return name.split(" ").map((n) => n[0]).join("");
    }
    return ""
  }

  if (bordered === true) {
    return (
      <Box sx={{ width: `${viewSize()}pt`, height: `${viewSize()}pt`, border: (theme) => `1pt solid ${theme.palette.primary.main}`, borderRadius: `${Math.round(viewSize() / 2)}pt` }}>
        <Avatar src={user?.profilePic} sx={{ backgroundColor: grey[800], left: "2pt", top: "2pt", width: "calc(100% - 4pt)", height: "calc(100% - 4pt)", color: "white", fontSize: `${Math.round(viewSize() * 0.4)}pt` }}>{initials()}</Avatar>
      </Box>
    )
  } else {
    return (
      <Avatar src={user?.profilePic} sx={{ backgroundColor: grey[800], width: `${viewSize()}pt`, height: `${viewSize()}pt`, color: "white", fontSize: `${Math.round(viewSize() * 0.4)}pt` }}>{initials()}</Avatar>
    )
  }
}

export default UserAvatar