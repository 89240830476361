import { lazy, Suspense, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Modal, Box, CssBaseline, Paper, CircularProgress } from '@mui/material';

import NavBar from "./UI/Components/Nav Bar/NavBar";

import IdentityCard from "./UI/Pages/IdentityCard/IdentityCard";
import { Context } from "./State/ContextProvider";
import UserService from "./Services/User/UserService";
import authService from "./Services/Auth/AuthenticationService";
import ThemeProvider from "./Theme/ThemeProvider";
import Footer from "./UI/Components/Footer/Footer";
import envHelper from "./Utilities/EnvironmentHelper";

const Home = lazy(() => import('./UI/Pages/Home/Home'));
const ScanConnection = lazy(() => import('./UI/Pages/ScanConnection'));
const Login = lazy(() => import('./UI/Pages/Auth/Login'));
const CreateAccount = lazy(() => import('./UI/Pages/Auth/CreateAccount'));
const AccountValidation = lazy(() => import('./UI/Pages/Auth/AccountValidation'));

const App = (): JSX.Element => {

  // Accessors
  const location = useLocation()
  const navigate = useNavigate()
  const { state, updateSignInState, updateUser, updateSelectedConnection } = useContext(Context)
  const [modalIsOpen, setIsOpen] = useState(false)

  const [, setFooterHeight] = useState(0)
  var footerRef = useRef()

  // Private methods
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    updateSelectedConnection!(undefined)
    setIsOpen(false)
    navigate("/")
  }

  const getUser = useCallback(() => {
    console.log("Get User")
    let service = new UserService();
    service.getSelf().then(
      (user) => {
        console.log("Update user")
        updateUser!(user)
      },
      (error) => {
        console.error("Get user error: " + error.description)
      }
    );
  }, [updateUser])

  const getCredentials = useCallback(() => {
    authService.isAuthenticated().then(
        (isAuthed) => {
            console.log("Is Authenticated: " + isAuthed)
            updateSignInState!(isAuthed)
            console.log("Getting user")
            getUser()
        }
    )
  }, [getUser, updateSignInState])

  // On Load
  useEffect(() => {
    if (location.pathname.startsWith("/connection/")) {
      openModal()
    }
  }, [location])

  useEffect(() => {
    if (state.currentUser === undefined) {
      console.log("Get credentials")
      getCredentials()
    }
  }, [])

  useEffect(() => { 
    envHelper.clearCookiesIfNeeded()
    console.log("Current User: " + state.currentUser)
  }, [])

  const loadingScreen: React.FC = () => {
    return(
      <Box display="flex" flex={1}>
        <CircularProgress sx={{ margin: "auto" }}/>
      </Box>
    )
  }

  useLayoutEffect(() => {
    if (footerRef?.current != null) {
      setFooterHeight(footerRef.current["clientHeight"])
    }
  })

  return (
    <ThemeProvider>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column" , minHeight: "100vh", alignItems: "stretch"}}>
        <NavBar />
        { state.currentUser !== undefined ? 
          <Box sx={{ margin: { mobile: "12pt 0pt 24pt 0pt", tablet: "24pt auto" }, width: { mobile: "100%", tablet: "70%" } }} flex={1}>
            <Box sx={{ height: (theme) => theme.mixins.toolbar }}></Box>
            <Suspense fallback={loadingScreen({})}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<CreateAccount />} />
                <Route path="/validate" element={<AccountValidation />} />
                <Route path="/share/:connection_id" element={<ScanConnection />} />
                <Route path="/connection/*" element={<Home />} />
                <Route path="/" element={<Home />} />
              </Routes>
              <Modal 
                open={modalIsOpen} 
                onClose={closeModal}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}
                slotProps={{ backdrop: { style: { backgroundColor: "#0003", backdropFilter: "blur(2px)", WebkitBackdropFilter: "blur(2px)" } } }}>
                <Box sx={{ 
                  position: "relative",
                  top: "50%",
                  transform: "translate(-0%, -50%)",
                  margin: "auto", 
                  maxWidth: "380pt",
                  minWidth: "100pt",
                  minHeight: "100pt",
                  padding: "0pt 12pt"
                }}>
                  <Paper
                    sx={{
                      borderRadius: "8pt",
                      border: "1pt solid #fff2",
                      boxShadow: (theme) => theme.palette.mode == "dark" ? "0pt 0pt 50pt 25pt #0003" : "0pt 0pt 50pt #888",
                      overflow: "hidden",
                      backgroundColor: "#0000",
                      backdropFilter: "blur(10px)",
                      WebkitBackdropFilter: "blur(10px)"
                    }}>
                    <Routes>
                      <Route path="/connection/:connection_id" element={<IdentityCard />} />
                      <Route path="/" element={<></>} />
                    </Routes>
                  </Paper>
                </Box>
              </Modal>
            </Suspense>
          </Box>
        :
          loadingScreen({})
        }
        <Footer ref={footerRef} />
      </Box>
    </ThemeProvider>
  )
}

export default App