import Cookies from "js-cookie";
import authService from "../Auth/AuthenticationService";
import axios from 'axios';
import { Config } from "../../Utilities/Config";

var apiClientFactory = require('aws-api-gateway-client').default;

type Result<T> = T | Error;

interface Request {
  request: { 
    method: string,
    urlTemplate: string, 
    body: any | undefined,
    params: any | undefined
  }, 
  completion: (result: Result<any>) => void
}

class APINetworkClient {

  private static _instance: APINetworkClient;

  private apiClient: any;
  private requests: Request[] = []
  private isRunning: boolean

  private constructor() {
      this.isRunning = false
  }

  public static get Instance()
  {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  public request(method: string, url: string, body: any | undefined, params: any | undefined, completion: (result: Result<any>) => void) {    
    let request = {
      request: {
        method: method,
        urlTemplate: url,
        body: body,
        params: params
      },
      completion: completion
    }
    this.requests.push(request)
    this.nextRequest()
  }

  private async nextRequest() {
    if (this.isRunning === true) {
      return
    }

    this.isRunning = true

    await this.updateCredentials()
    
    let request = this.requests.shift()
    if (request === undefined) {
      this.isRunning = false
      return
    }

    const complete = () => {
      this.isRunning = false
      this.nextRequest()
    }

    this.apiClient.invokeApi(request.request.params, request.request.urlTemplate, request.request.method, undefined, request.request.body).then(
      (message: any) => {
          request?.completion(message.data)
          complete()
      },
      (error: any) => {
          if (axios.isAxiosError(error)) {
            console.log(error.request)
          }
          request?.completion(error)
          complete()
      }
    )
  }

  private async updateCredentials() {

    if (!authService.hasCredentials()) {
      console.log("Fetch credentials for request")
      await authService.fetchCredentials()
    }

    const ak = Cookies.get('ak');
    const sk = Cookies.get('sk');
    const st = Cookies.get('st');

    this.apiClient = apiClientFactory.newClient({
        invokeUrl: Config.apiEndpoint(),
        region: 'eu-west-2',
        accessKey: ak,
        secretKey: sk,
        sessionToken: st
    });
  }
}

const apiNetworkingClient = APINetworkClient.Instance;
export default apiNetworkingClient;